.App {
  text-align: center;
  margin: 0 auto;
}

.header {
  height: 160px;
  align-items: center;
  justify-content: center;
  display: flex;
  font-size: 24px;
  font-weight: bold;
  background: rgb(90,119,219);
  background: linear-gradient(180deg, rgba(90,119,219,1) 0%, rgba(70,54,169,1) 100%);
  color: #fff;
  padding-bottom: 40px;
}

.app-logo {
  width: 32px;
  margin-right: 8px;
  border-radius: 8px;
}

.content {
  max-width: 600px;
  margin: -80px auto 0;
  padding: 8px;
  font-size: 14px;
}

.card {
  padding: 16px 8px;
  border-radius: 16px;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  margin-bottom: 16px;
  position: relative;
}

.card-title {
  font-size: 12px;
  color: rgb(135,134,137);
}

.collapse-indicator {
  position: absolute;
  right: 16px;
  font-weight: bold;
  font-size: 20px;
  color: rgb(135,134,137);
  top: 15px;
  transition: transform linear .1s;
}

.collapse-indicator.rotate {
  transform: rotate(180deg);
  top: 10px;
}

p.date-indicator {
  font-size: 18px;
  margin: 12px 0 8px;
  font-weight: 500;
}

p.time-indicator {
  font-size: 40px;
  margin: 0px;
  font-weight: bold;
}

button.btn-measure {
  margin-top: 16px;
  margin-bottom: 8px;
  padding: 16px;
  border-radius: 16px;
  border: none;
  background: rgba(90,119,219,1);
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  width: 60%;
}

ul.no-bullet {
  list-style-type: none;
  padding: 8px 0;
  margin: 0;
}

ul.no-bullet li {
  padding: 2px 0;
  color: rgba(70,54,169,1);
}

.footer {
  margin-top: 32px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  flex-direction: column;
}

.footer p {
  margin: 4px 0;
}

.link.danger {
  color: #DC3545;
  font-weight: 600;
}
